<nav>
  <mri-shared-main-nav-bar
    [menuItems]="menu.mainMenuItems$ | async"
    [headerLogo]="headerLogo"
  ></mri-shared-main-nav-bar>
</nav>
<main kendoDialogContainer>
  <mri-shared-nav-drawer></mri-shared-nav-drawer>
  <mri-shared-page-loading></mri-shared-page-loading>
  <router-outlet></router-outlet>
  <router-outlet name="drawer"></router-outlet>
</main>
<ng-content select="[pageFooter]"></ng-content>
