import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '@mri-platform/ag-shared/services';
import { MenuService } from '@mri-platform/shared/shell';
import { HeaderLogo } from '@mri-platform/shared/ui-main-nav-bar-updated';

/**
 * todo: delete this component once flix is moved to a lazy loaded route
 */
@Component({
  selector: 'mri-ag-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  @Input() showXLogo = true;

  headerLogo: HeaderLogo = {
    url: 'assets/agora-text.svg',
    text: 'agora gateway administration'
  };

  constructor(
    public menu: MenuService,
    private clientService: ClientService,
    private router: Router
  ) {
    if (this.clientService.getClientIdSet() === false) {
      this.router.navigate([`./client-selection`]);
    }
  }
}
