import { Injectable } from '@angular/core';
import { AppRoutes } from '@mri-platform/ag-admin/core';
import { RouteMetadata, Settings } from '@mri-platform/shared/core';
import { MainMenuItemService } from '@mri-platform/shared/shell';
import { DrawerMenuItem, LinkMenuItem, ModalMenuItem } from '@mri-platform/shared/ui-main-nav-bar-updated';
import { AppGatewayConfig } from '@mri/app-gateway-bar';
import { FlixModalService } from '../flix-modal-content/flix-modal.service';

@Injectable()
export class AppMainMenuItemService implements MainMenuItemService {
  private appsUsers: DrawerMenuItem<RouteMetadata> = {
    iconName: 'backup_table',
    title: 'Apps and users',
    links: [
      {
        text: 'Applications',
        ...AppRoutes.Applications.toMenuInfo(),
        url: `admin${AppRoutes.Applications.toMenuInfo().url}`
      },
      { text: 'Users', ...AppRoutes.Users.toMenuInfo(), url: `admin${AppRoutes.Users.toMenuInfo().url}` },
      {
        text: 'User and application matrix',
        ...AppRoutes.ApplicationUserMatrix.toMenuInfo(),
        url: `admin${AppRoutes.ApplicationUserMatrix.toMenuInfo().url}`
      }
    ]
  };

  private agManagement: LinkMenuItem = {
    iconName: 'manage_accounts',
    title: 'Client setup',
    ...AppRoutes.UserManagement.toMenuInfo(),
    url: `admin${AppRoutes.UserManagement.toMenuInfo().url}`
  };

  private messages: LinkMenuItem = {
    iconName: 'campaign',
    title: 'Messages',
    ...AppRoutes.Messages.toMenuInfo(),
    url: `admin${AppRoutes.Messages.toMenuInfo().url}`
  };

  private flix: ModalMenuItem = {
    iconName: 'ondemand_video',
    title: 'Flix video',
    open: this.flixModal.openFlixModal.bind(this.flixModal)
  };

  private settings: DrawerMenuItem = {
    iconName: 'settings',
    title: 'Setup',
    cssClass: ['settings-icon'],
    links: [
      {
        text: 'Client settings',
        ...AppRoutes.ClientSettings.HomePage.toMenuInfo(),
        url: `admin${AppRoutes.ClientSettings.HomePage.toMenuInfo().url}`
      }
    ]
  };

  private helpCenter: DrawerMenuItem<RouteMetadata> = {
    iconName: 'help_outline',
    title: 'Help',
    cssClass: ['help-center-icon'],
    links: []
  };

  constructor(
    private flixModal: FlixModalService,
    globalSettings: Settings
  ) {
    this.helpCenter.links = this.getHelpCenterLinks(globalSettings);
  }

  private getHelpCenterLinks(globalSettings: Settings) {
    const appGateway = globalSettings.appGateway as AppGatewayConfig;
    return [
      {
        text: 'User Guide',
        url: './assets/documents/MRI Agora Gateway User Guide Version 5.00.pdf',
        isExternalLink: true
      },
      { text: 'Training Academy', url: appGateway.trainingAcademyUrl, isExternalLink: true },
      { text: 'MRI support', url: 'https://www.mrisoftware.com/services/contact-support/', isExternalLink: true }
    ];
  }

  get items() {
    return [this.appsUsers, this.agManagement, this.messages, this.settings, this.flix, this.helpCenter];
  }
}
