import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from '@mri-platform/ag-admin/core';
import { MainComponent, ShellComponent } from '@mri-platform/ag-admin/shell';
import { ClientSelectionLandingComponent } from '@mri-platform/ag-shared/components';
import { UnhandledErrorPageComponent } from '@mri-platform/shared/shell';
import { AuthGuard, AuthzAppUserGuard } from '@mri/angular-wfe-proxy-oidc';
import { ClientGuard } from './guards/client.guard';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'admin',
        canActivate: [AuthGuard, AuthzAppUserGuard, ClientGuard],
        component: ShellComponent,
        children: [
          {
            path: AppRoutes.ApplicationUserMatrix.path,
            loadChildren: () =>
              import('@mri-platform/ag-admin/user-applications-matrix').then(
                module => module.UserApplicationsMatrixModule
              )
          },
          {
            path: AppRoutes.Applications.path,
            loadChildren: () => import('@mri-platform/ag-admin/applications').then(module => module.ApplicationsModule)
          },
          {
            path: AppRoutes.UserManagement.path,
            loadChildren: () =>
              import('@mri-platform/ag-admin/user-management').then(module => module.UserManagementModule)
          },
          {
            path: AppRoutes.Users.path,
            loadChildren: () => import('@mri-platform/ag-admin/users').then(module => module.UsersModule)
          },
          {
            path: AppRoutes.Messages.path,
            loadChildren: () => import('@mri-platform/ag-admin/messages').then(module => module.MessagesModule)
          },
          {
            path: AppRoutes.ClientSettings.path,
            loadChildren: () =>
              import('@mri-platform/ag-admin/client-settings').then(module => module.ClientSettingsModule)
          },
          { path: 'error', component: UnhandledErrorPageComponent },
          { path: '', redirectTo: AppRoutes.Applications.path, pathMatch: 'full' }
        ]
      },
      {
        path: 'client-selection',
        component: ClientSelectionLandingComponent,
        canActivate: [AuthGuard, AuthzAppUserGuard]
      },
      { path: '', redirectTo: '/client-selection', pathMatch: 'full' },
      { path: '**', redirectTo: '/client-selection', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
      // enableTracing: !environment.production
    })
  ],
  exports: [RouterModule],
  declarations: [],
  providers: []
})
export class AppRoutingModule {}
