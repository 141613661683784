import { Provider } from '@angular/core';
import { ValidationHandler } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

/**
 * Implicit flow ONLY token validator (todo: remove once app has switched to code+pkce flow)
 */
export const jwkValidationHandlerProvider: Provider = {
  provide: ValidationHandler,
  useClass: JwksValidationHandler
};
