import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainMenuItemService, SharedShellModule } from '@mri-platform/shared/shell';
import { UiMainNavBarModule } from '@mri-platform/shared/ui-main-nav-bar-updated';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FlixModalContentComponent } from './flix-modal-content/flix-modal-content.component';
import { MainComponent } from './main/main.component';
import { ShellComponent } from './shell/shell.component';
import { AppMainMenuItemService } from './ui-services';

@NgModule({
  imports: [
    CommonModule,
    DialogModule, // todo: delete this once flix is moved to a lazy loaded route
    SharedShellModule,
    RouterModule,
    UiMainNavBarModule
  ],
  providers: [{ provide: MainMenuItemService, useClass: AppMainMenuItemService }],
  declarations: [
    ShellComponent, // todo: delete this once flix is moved to a lazy loaded route
    FlixModalContentComponent,
    MainComponent // todo: delete this once flix is moved to a lazy loaded route
  ]
})
export class ShellModule {}

// make public (ie available outside of this library)...
export { MainComponent, ShellComponent }; // todo: delete this once flix is moved to a lazy loaded route
