import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@mri-platform/ag-admin/core';
import { ServicesModule, StateModule } from '@mri-platform/ag-admin/services';
import { ShellModule } from '@mri-platform/ag-admin/shell';
import { SharedServicesModule } from '@mri-platform/ag-shared/services';
import { AppCoreIntegrationModule } from '@mri-platform/shared/app-core-integration';
import { AppInsightsIntegrationModule } from '@mri-platform/shared/app-insights-integration';
import { AppNgrxDataIntegrationModule } from '@mri-platform/shared/app-ngrx-data-integration';
import { NavigationModule } from '@mri-platform/shared/app-ngrx-integration';
import { environment, FAKE_DB_COLLECTIONS_TOKEN, SharedCoreModule } from '@mri-platform/shared/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './containers/app.component';
import { isAuthzAppUserPredicateProvider, jwkValidationHandlerProvider } from './integration';
import { ROOT_REDUCERS } from './reducers';
import { devDb } from './testing/dev-dataset';
import { testDb } from './testing/test-dataset';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: true,
        strictStateSerializability: true
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal
    }),
    CoreModule,
    AppInsightsIntegrationModule,
    AppCoreIntegrationModule.forRoot(),
    AppNgrxDataIntegrationModule.forRoot(),
    NavigationModule,
    ShellModule,
    BrowserAnimationsModule,
    SharedServicesModule,
    ServicesModule,
    StateModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    SharedCoreModule.forRoot().providers ?? [],
    isAuthzAppUserPredicateProvider,
    jwkValidationHandlerProvider, // todo: delete provider once app has switched to code+pkce flow
    {
      provide: FAKE_DB_COLLECTIONS_TOKEN,
      multi: true,
      useValue: environment.dev ? devDb : environment.integrationTest ? testDb : {}
    }
  ]
})
export class AppModule {}
