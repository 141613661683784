import { Component } from '@angular/core';
import { ClientService } from '@mri-platform/ag-shared/services';
import { MainNavService } from '@mri-platform/shared/ui-main-nav-bar-updated';
import { filter, tap } from 'rxjs';

/**
 * todo: delete this component once flix is moved to a lazy loaded route
 */
@Component({
  selector: 'mri-ag-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  constructor(
    private mainMenuService: MainNavService,
    private clientService: ClientService
  ) {
    this.clientService.setFromAdmin(true);
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        tap(clientId => {
          this.mainMenuService.setClientId(clientId ?? '');
        })
      )
      .subscribe();
  }
}
