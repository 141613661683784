import { Component } from '@angular/core';
import { AppInsightsService } from '@mri-platform/angular-app-insights';

@Component({
  selector: 'mri-ag-root',
  template: ` <router-outlet></router-outlet> `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `
  ],
  providers: []
})
export class AppComponent {
  constructor(appInsights: AppInsightsService) {
    appInsights.whenEnabled(ai => ai.trackEvent({ name: 'app-started' }));
  }
}
