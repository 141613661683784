import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MainNavService } from '@mri-platform/shared/ui-main-nav-bar-updated';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { FlixModalContentComponent } from './flix-modal-content.component';

@Injectable({ providedIn: 'root' })
export class FlixModalService {
  constructor(
    private dialogService: DialogService,
    private mainNavService: MainNavService,
    private titleService: Title
  ) {}

  openFlixModal() {
    this.dialogService.open({
      // having a non-empty string here forces the title bar to render, so we get the default X icon to close.
      title: `${this.titleService.getTitle()} Flix`,
      content: FlixModalContentComponent,
      minWidth: '50%',
      maxWidth: '80%',
      preventAction: dialogEvent => {
        if (dialogEvent instanceof DialogCloseResult) {
          this.mainNavService.activate(null);
        }
        //using this as a hook to deactivate the menu item on the close event without a full custom close button.
        return false;
      }
    });
  }
}
