import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ClientService } from '@mri-platform/ag-shared/services';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate {
  constructor(
    private clientService: ClientService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.clientService.getClientId().pipe(
      map(clientId => {
        if (clientId !== undefined) {
          return true;
        }
        this.router.navigate(['/client-selection']);
        return false;
      })
    );
  }
}
