import { Provider } from '@angular/core';
import { AppUserAttributes } from '@mri-platform/ag-admin/core';
import { IS_AUTHZ_APP_USER_PREDICATE } from '@mri-platform/shared/app-core-integration';
import { IsAuthzAppUserPredicate } from '@mri/angular-wfe-proxy-oidc';

export const isAuthzAppUser: IsAuthzAppUserPredicate<AppUserAttributes> = () => true;

export const isAuthzAppUserPredicateProvider: Provider = {
  provide: IS_AUTHZ_APP_USER_PREDICATE,
  useValue: isAuthzAppUser
};
