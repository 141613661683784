import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'mri-ag-flix-modal-content',
  template: `
    <iframe class="flix-video" src="https://player.vimeo.com/video/434493397" allow="autoplay; fullscreen"></iframe>
    <h3 class="flix-caption mri-heading--3 mri-color-blue-55">
      Watch our Flix video to learn more about {{ appName }} Site
    </h3>
  `,
  styles: [
    `
      :host {
        display: grid;
        grid-template-areas:
          'video'
          'caption';
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr;
        justify-items: center;
        justify-content: center;
        padding-top: 0;
        width: 100%;
        height: 100%;
      }

      iframe.flix-video {
        grid-area: video;
        border: none;
        width: 100%;
        min-height: 360px;
        max-height: 100%;
      }

      h3.flix-caption {
        grid-area: caption;
        text-align: center;
        width: 75%;
      }
    `
  ]
})
export class FlixModalContentComponent {
  appName = '';
  constructor(titleService: Title) {
    this.appName = titleService.getTitle();
  }
}
