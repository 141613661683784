import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducerMap } from '@ngrx/store';

export interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: fromRouter.RouterReducerState<any>;
}

export const ROOT_REDUCERS: ActionReducerMap<State, Action> = {
  router: fromRouter.routerReducer
};
